import React from "react";
import {Layout, SEO} from "../components";
import {NewsBannerBlock, LatestNewsListing} from '../components'
import {Helmet} from "react-helmet";

const News = () => {
    return (
        <>
            <Layout>
                <Helmet title='MusicRoom News Platform'/>

                <NewsBannerBlock/>
                <LatestNewsListing/>
            </Layout>
        </>
    )
}

export default News;